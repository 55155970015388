//
//
//
//
// This file is saved for reference, but if you need to make changes to the
// recipe-detail page, please use the templates/recipe-detail file
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';

import PagesHero from '../components/PagesHero';
// import Heading from '../components/Heading';
import placeHolderImage from '../images/placeholder.png';
import RegularButton from '../components/RegularButton';
import ShareWidget from '../components/ShareWidget';
import { breakpoints } from './../style-utilities/variables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle, faHeart } from '@fortawesome/free-regular-svg-icons';
import { faDownload, faPrint } from '@fortawesome/free-solid-svg-icons';

const RecipeSection = styled.div``;

const TopSection = styled.div`
  .ImgContainer {
    height: 300px;
    width: 100%;
    background-image: url(${placeHolderImage});
    background-size: cover;
    background-repeat: no-repeat;
  }
  .widget-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    flex-direction: column;
    .button {
      display: inline-block;
      margin: 0 10px;
    }
  }
  @media (min-width: ${breakpoints.desktop}) {
    .ImgContainer {
      position: absolute;
      z-index: 0;
      max-width: 1400px;
    }
    .HeroSection {
      align-items: center;
      display: grid;
      grid-template-columns: 60% 40%;
      grid-template-areas: 'img hero-recipe';
      max-width: 1400px;
      margin: 0 auto;
      img {
        grid-area: img;
        margin: auto;
        width: auto;
      }
      .hero-recipe {
        grid-area: hero-recipe;
        padding: 15px;
        z-index: 1;
        background: peru;
        border-radius: 5px;
        margin-right: 60px;
        color: white;
        hr {
          color: white;
        }
        a {
          color: white;
        }
        .ReviewAndWatch {
          display: flex;
          align-items: center;
          a {
            text-decoration: none;
          }
          p {
            padding: 0;
            margin: 0;
          }
        }
      }
    }
  }
`;

const StyledRecipeDetail = styled.div`
  display: inline-block;
  margin: 0 25px 0 0;
  img {
    height: 80px;
  }
`;

const Div = styled.div`
  height: auto;
`;

const recipeDetail = [
  {
    img: placeHolderImage,
    title: 'Prep Time:',
    content: '30 min'
  },
  {
    img: placeHolderImage,
    title: 'Total Time:',
    content: '1 hr'
  },
  {
    img: placeHolderImage,
    title: 'Serves:',
    content: '6 people'
  }
];

class RecipeDetail extends Component {
  renderRecipeDetail = () => {
    return recipeDetail.map(item => {
      return (
        <StyledRecipeDetail key={item.title}>
          {/* <img src={item.img} alt="imageIcon" /> */}
          <p>{ReactHtmlParser(item.title)}</p>
          <p>{item.content}</p>
        </StyledRecipeDetail>
      );
    });
  };

  render() {
    // const { title } = this.props.data.wordpressWpRecipes;
    return (
      <Div>
        <TopSection>
          <PagesHero className="HeroSection">
            <div className="ImgContainer" />
            <div className="hero-recipe">
              <h1>Baked Lasagna with risotta cream cheese frosting</h1>
              <div className="ReviewAndWatch">
                <p>STAR Review Component</p>
                &nbsp;&nbsp;
                <a href="#">
                  <FontAwesomeIcon icon={faPlayCircle} /> WATCH
                </a>
              </div>
              <p>
                Lorem ipsum dolor sit amet, conse adipiscing elit. Aenean
                euismod bibendum laoreet. Lorem
              </p>
              {this.renderRecipeDetail()}
              <hr />
            </div>
          </PagesHero>
          <div className="widget-section">
            <ShareWidget className="share-widget" />
            <div className="button favorite">
              <FontAwesomeIcon icon={faHeart} /> Save as Favorite
            </div>
            <div className="button download">
              <FontAwesomeIcon icon={faDownload} /> Download as PDF
            </div>
            <div className="button print">
              <FontAwesomeIcon icon={faPrint} /> Print this Recipe
            </div>
          </div>
        </TopSection>

        <RecipeSection />
      </Div>
    );
  }
}

export default RecipeDetail;
