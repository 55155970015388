import React, { Component } from 'react';
import styled from 'styled-components';
import ComponentStyles from '../brand-styles/components/ShareWidget.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { breakpoints, colors } from './../style-utilities/variables';
import {
  faFacebookF,
  faFacebookMessenger,
  faPinterest,
  faTwitter
} from '@fortawesome/free-brands-svg-icons';

const StyledSocial = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  @media (min-width: ${breakpoints.desktop}) {
    justify-content: left;
  }
  li {
    display: inline-block;
    text-decoration: none;
    width: 50px;
    font-size: 13px;
  }
  li.facebookFix {
    width: 38px;
  }
  li,
  li svg {
    color: ${colors.primary};
    cursor: pointer;
  }

  /* Dynamic Brand Styles */
  ${ComponentStyles}
`;

class ShareWidget extends Component {
  state = {
    currentUrl: ''
  };

  componentDidMount() {
    this.setState({
      currentUrl: encodeURIComponent(window.location.href)
    });
  }

  render() {
    const { currentUrl } = this.state;
    return (
      <StyledSocial>
        <div className="a2a_kit a2a_kit_size_32 a2a_default_style">
          {this.props.hideAPinButton ? null : (
            <li>
              <a
                className="pin-link"
                data-pin-custom="true"
                href={`https://www.pinterest.com/pin/create/button/?url=${decodeURIComponent(
                  this.state.currentUrl
                )}&media=${this.props.pinterest_image_url}&description=${
                  this.props.pinterestTitle
                }`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faPinterest}
                  size="2x"
                  style={{ width: '32px', height: '32px' }}
                />
              </a>
            </li>
          )}

          <li className="facebookFix">
            <a
              href={`https://www.addtoany.com/add_to/facebook?linkurl=${decodeURIComponent(
                currentUrl
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faFacebookF}
                size="2x"
                style={{ width: '18px', height: '32px' }}
              />
            </a>
          </li>
          <li>
            <a
              href={`https://www.addtoany.com/add_to/twitter?linkurl=${decodeURIComponent(
                currentUrl
              )}&linknote=Twitter`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faTwitter}
                size="2x"
                style={{ width: '32px', height: '32px' }}
              />
            </a>
          </li>
          <li>
            <a
              href={`https://www.facebook.com/dialog/send?app_id=${
                process.env.FACEBOOK_AUTH_APP_ID
              }&link=${decodeURIComponent(
                currentUrl
              )}&redirect_uri=${decodeURIComponent(currentUrl)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faFacebookMessenger}
                size="2x"
                style={{ width: '28px', height: '32px' }}
              />
            </a>
          </li>
        </div>
      </StyledSocial>
    );
  }
}
export default ShareWidget;
